import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { ConfigProvider } from "antd";
import App from './App'
import { HashRouter } from 'react-router-dom';
import zhCN from 'antd/locale/zh_CN';
// import store from "./store/index";
import { Provider } from "react-redux";

// import 'default-passive-events'

import SkeletonDom from '~/components/skeleton';
// 全局字体图标
import "~/assets/iconfont/iconfont.css"
// 全局样式
import '~/assets/style/global.scss'

const root = createRoot(document.getElementById("root"));
// 插入的模版，目标位置
root.render(
  <HashRouter>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#1677ff",
        },
      }}
      locale={zhCN}
    >

      <Suspense fallback={<SkeletonDom />}>
        <App />
      </Suspense>
    </ConfigProvider>

  </HashRouter>
)
// 1.联调数据链路全景视图页面,(4h)
// 2.联调链路场景页面(4h)
