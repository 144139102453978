// 判断接口
import axios from "axios";
import { BAS, HAR } from "./ip-config";
import {
    message,
} from "antd";
/**
 * 数组移除某个元素
 * @param {*} object 
 * @param {*} key 
 * @param {*} value 
 * @eq List.remove('id', 1)
 */
Array.prototype.remove = function (key, value) {
    var index = this.findIndex(item => item[key] === value);
    if (index > -1) {
        this.splice(index, 1);
    }
};


/**
 * 数组替换某个元素
 * @param {*} object 
 * @param {*} key 
 * @param {*} value 
 * @eq List.replace(item,'id', 1)
 */

Array.prototype.replace = function (object, key, value) {
    var index = this.findIndex(item => item[key] === value);
    if (index > -1) {
        this.splice(index, 1, object);
    }
};

// JSON.parese后的顺序保持不变
export const JsonParseNoSort = function (jsonString) {
    const mapObject = JSON.parse(jsonString, (key, value) => {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            return new Map(Object.entries(value));
        }
        return value;
    });
    const jsonObject = Object.fromEntries(mapObject);

    // const jsonString1 = JSON.stringify(jsonObject1);
    // console.log(jsonString1);
    return jsonObject
}

export const ApiRequest = (item) => {
    switch (item) {
        case "BAS":
            axios.defaults.baseURL = BAS;
            break;
        case "HAR":
            axios.defaults.baseURL = HAR;
            break;
        default:
            axios.defaults.baseURL = BAS;
            break;
    }
};

// 跳转页面
export function goto(url) {
    window.location.hash = url;
}

/**debounce
 * @desc 函数防抖---“立即执行版本” 和 “非立即执行版本” 的组合版本
 * @param func 需要执行的函数
 * @param wait 延迟执行时间（毫秒）
 * @param immediate---true 表立即执行，false 表非立即执行
 **/
export function debounce(func, wait, immediate) {
    let timer

    return function () {
        let context = this
        let args = arguments

        if (timer) clearTimeout(timer)
        if (immediate) {
            var callNow = !timer
            timer = setTimeout(() => {
                timer = null
            }, wait)
            if (callNow) func.apply(context, args)
        } else {
            timer = setTimeout(function () {
                func.apply(context, args)
            }, wait)
        }
    }
}
// 获取地址栏query参数： getQueryParams('uuid')
export function getQueryParams(paraName) {
    var url = decodeURIComponent(document.location.toString())
    var arrObj = url.split('?')
    if (arrObj.length > 1) {
        var arrPara = arrObj[1].split('&')
        var arr
        for (var i = 0; i < arrPara.length; i++) {
            arr = arrPara[i].split('=')
            if (arr != null && arr[0] == paraName) {
                return arr[1]
            }
        }
        return ''
    } else {
        return ''
    }
}
/**
 * 查找列表中是否有某个元素
 * @param {*} list 目标数组
 * @param {*} attr 目标属性['attr1','attr2'] || 'attr'
 * @param {*} type 属性值['type1',type2] || 'type'
 * @returns 
 */
export const listFindItem = (list, attr, type) => {
    if (!list) return ''
    return list.find(item => {
        let result = false
        if (typeof attr == 'string') {
            result = item[attr] == type
        } else {
            result = item[attr[0]] == type[0] && item[attr[1]] == type[1]
        }

        return result
    });
}
// 解析search传参
export function getSearch(str) {
    if (str.indexOf("?") != -1) {
        let obj = {};
        str
            .split("?")[1]
            .split("&")
            .map((el) => {
                let arr = el.split("=");
                obj[arr[0]] = arr[1];
            });
        return obj;
    } else {
        return;
    }
}

// 转换日期格式
export function renderTime(date) {
    var dateee = new Date(date).toJSON();
    return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
}


export const searchRoute = (path, routes) => {
    let result = {};
    for (let item of routes) {
        //递归条件

        if (item.path === path) return item;
        if (item.children) {
            const res = searchRoute(path, item.children);
            if (Object.keys(res).length) result = res;
        }
    }

    return result;
};

// 判断的是否是JSON字符串
export const isType = (str) => {
    if (typeof str == "string") {
        try {
            var obj = JSON.parse(str);
            // 等于这个条件说明就是JSON字符串 会返回true
            if (typeof obj == "object" && obj) {
                return true;
            } else {
                //不是就返回false
                return false;
            }
        } catch (e) {
            return false;
        }
    }
    return false;
};

// 新开窗口
export const openNewWindow = (url) => {
    const canOpenWindow = JSON.parse(localStorage.getItem("hasWindow") || false);

    // if (!canOpenWindow) {
    // localStorage.setItem("hasWindow", true);
    // 桌面打包后，/#/ 作为系统根目录，无法识别，需完整路径
    const MainUrl = window.location.pathname + url;
    window.open(
        MainUrl
    );
    // } else {
    //     message.warning('编辑和合成窗口，只能同时打开一个！')
    // }
}

