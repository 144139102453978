import { Navigate, useLocation } from 'react-router-dom'
import { goto } from '~/utils/jstools';
import routes from './index'
// 白名单列表
const whiteList = ['/login', '/404', '/cubeRoot', '/tree-root-ZZTA', '/digital-twin-ZZTB', '/digital-twin-ZZTA', '/digital-twin-PC', '/digital-twin-HFCR']

export default function Author(props) {
    // console.log("🚀 ~ file: author.js:8 ~ Author ~ props:", props)
    const { children } = props
    const { pathname, search = '' } = useLocation()
    // console.log("author----", routes, pathname, search)

    // 匹配路由添加query
    const matchRoute = (list, path, value) => {
        // 首页除外
        if (path == '/') return
        list.forEach(element => {
            if (element.path === path && value) {
                element.query = value
            }
            if (element?.children) {
                matchRoute(element?.children, path, value)
            }
        })

        // console.log("🚀 ~ file: author.js:15 ~ metchRoute ~ route:", list)

        // return route
    }
    // 发现路径的时候，匹配路由，添加
    if (pathname) {
        matchRoute(routes, pathname, search)
    }

    // 白名单直接跳转
    if (whiteList.indexOf(pathname) !== -1) {
        return children
    }

    const userInfo = window.localStorage.getItem("userInfo") || '{}'
    let token = JSON.parse(userInfo).token

    if (!token) {
        // return goto(`/login?path=${encodeURIComponent(window.location.hash)}`)
        return <Navigate to={`/login?path=${encodeURIComponent(pathname + search)}`} />
    } else {
        return children
    }
}

