
import React, { lazy, } from "react";

import { Navigate } from 'react-router-dom'
import { nanoid } from 'nanoid'

/**
 * 通用页面
 */

// 登录
const Login = lazy(() => import('~/views/login'))


// 404
const Error404 = lazy(() => import('~/views/error/404'))

// 布局组件
const HomeLayout = lazy(() => import('~/layout/home'))

// 首页
const Home = lazy(() => import('~/views/home/index'))

const commonRoutes = [

    // 通用路由
    {
        path: "*",
        element: <Navigate to="/404" replace />
    },
    {
        path: "/404",
        element: <Error404 />
    },
    {
        path: "/403",
        element: <Error404 />
    },
    {
        path: "/405",
        element: <Error404 />
    },
    {
        path: '/login', element: <Login />,
    },
    // 业务路由
    {
        path: '/', key: nanoid(), element: <HomeLayout />,

        children: [
            {
                path: '',
                key: nanoid(),
                element: <Home />,
                name: '首页',
            },

        ],
    },
]

export default commonRoutes;