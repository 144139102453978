import { useState, useEffect } from 'react'
import { store } from "~/store";
import routers from './router/index'
import { Spin } from "antd";
import { NavLink, useRoutes, Outlet, useLocation } from 'react-router-dom';
import Author from './router/author';

import './App.scss'


// import Vconsole from 'vconsole'; // 引入vconsole插件

export default function App() {
  const GetRoutes = () => useRoutes(routers); //一定要是函数内,路由表这根步注册一次就行 子路由无需注册

  // 全局loading
  const [isLoading, setIsLoading] = useState(false)
  const [guideInfoObj, setGuideInfoObj] = useState(false)
  // 监控dispatch提交的action改变
  store.subscribe(() => {
    const { settings, guideInfo, modelLoad } = store.getState();
    // console.log("🚀 ~ file: App.jsx:19 ~ store.subscribe ~ settings:", guideInfo)
    if (modelLoad.loading || settings.loading) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
    setGuideInfoObj(guideInfo)
  });

  // useEffect(() => {
  //   // new Vconsole(); // 初始化
  //   // vConsole.show();

  //   // var screenWidth = window.screen.width;
  //   // var actualWidth = screenWidth;

  //   // if (screenWidth > window.innerWidth) {
  //   //   actualWidth = window.innerWidth;
  //   // }

  //   // console.log("实际宽度: " + actualWidth);
  // }, [])

  // window.resizeTo(1440, 1080);
  // // 处理窗口关闭时，清楚已开窗口的标记
  // const handler = (e) => {
  //   localStorage.removeItem('hasWindow')
  // }
  // window.addEventListener('beforeunload', handler)


  return (
    <>
      <Spin spinning={isLoading} delay={0} size="large" wrapperClassName='rootSpin' style={{ width: '100%', height: '100%', position: 'fixed' }}>
        <Author>
          {GetRoutes()}
        </Author>
      </Spin>
    </>
  )
}


